<template>
    <div class="n-carousel"
        :style="`height:${height}`"
        @mouseover="stop"
        @mouseout="play"
    >
        <div class="options-list"
            :style="`
                width:${100*options.length}%;
                margin-left:-${100*thisCurrent}%;
            `"
        >
            <div class="item" v-for="(item, index) in options" :key="index">
                <img :src="item.img" />
            </div>
        </div>
        <div class="point-list">
            <template v-for="item in options.length" :key="item">
                <b 
                    :class="item-1 === thisCurrent ? 'current' : ''"
                    @click="thisCurrent = item-1"
                ></b>
            </template>
        </div>
    </div>
</template>
<script>
export default {
    name: 'NCarousel',
    props: {
        options: {
            type: Array,
            default: []
        },
        current: {
            type: Number,
            default: 0
        },
        interval: {
            type: Number,
            default: 3000
        },
        autoplay: {
            type: Boolean,
            default: true
        },
        height: {
            type: [Number, String],
            default: 'auto'
        }
    },
    data(){
        return {
            thisCurrent: this.current
        }
    },
    mounted(){
        this.play();
    },
    unmounted(){
        clearInterval(this.timer);
    },
    methods:{
        play(){
            this.timer = setInterval(() => {
                this.thisCurrent = this.thisCurrent < this.options.length - 1 ? this.thisCurrent + 1 : 0
            },this.interval);
        },
        stop(){
            clearInterval(this.timer);
        }
    }
}
</script>

<style lang="scss" scoped>
.n-carousel{
    position: relative;
    width: 100%;
    overflow: hidden;
    .options-list{
        @extend .flex-row;
        .item{
            text-align: center;
            width: 100%;
            img{
                width: 100%;
            }
        }
    }
    .point-list{
        @extend .flex-row-center;
        position: absolute;
        bottom: 15px;
        width: 100%;
        b{
            cursor: pointer;
            width: 15px;
            height: 15px;
            margin: 0 5px;
            border-radius: 100%;
            background-color: rgba(255,255,255,.5);
            &:hover,
            &.current{
                background-color: rgba(255,255,255,1);
            }
        }
    }
}
</style>