<template>
	<div class="page-home">
		<n-carousel 
			:options="carousel.options"
			:current="carousel.current"
		/>

		<div class="main-products">
			<div class="container">
				<div class="hd-box">
					<h4 class="cus-title">Main Products</h4>
					<div class="cat-links">
						<span class="link"
							:class="equipments.currentId == 2 ? 'current' : ''"
							@click="getEquipments(); equipments.currentId = 2"
						>All</span>
						<template v-for="(item, index) in equipments.cats" :key="index">
							<span class="link" 
								:class="equipments.currentId === item.id ? 'current' : ''"
								@click="getEquipments(item.id); equipments.currentId = item.id;"
							>{{ item.title }}</span>
						</template>
					</div>
				</div>
				<div class="bd-box">
					<template v-for="(item, index) in equipments.data" :key="index">
						<router-link :to="`/equipment/detail/${item.id}`">
							<h5>{{ item.name }}</h5>
							<img :src="item.thumb" />
						</router-link>
					</template>
				</div>
				<div class="ft-box">
					<router-link class="btn-link" :to="`/equipment/${equipments.currentId}`">Explore</router-link>
				</div>
			</div>
		</div>

		<div class="home-about">
			<div class="about-hd">
				<img class="bg" src="@/assets/images/home-about.png" />
				<div class="container">
					<h4 class="cus-title">About</h4>
					<p>Zhengzhou Xuanrun Machinery Equipment CO. LTD.</p>
				</div>
			</div>
			<div class="container about-bd">
				<img class="bg" :src="about.img" />
				<div class="box">
					<h4>{{ about.title }}</h4>
					<p>
						{{ about.content }}
					</p>
				</div>
			</div>
		</div>

		<div class="home-story">
			<div class="container">
				<div class="story-hd">
					<h4 class="cus-title">Our Story</h4>
					<p>
						Xuanrun Machinery Equipment CO.,Ltd<br>
						is one of the white hair for beer brewing system business in China.
					</p>
				</div>
				<div class="story-bd">
					<template v-for="(item, index) in stories" :key="index">
						<div class="item" @click="$router.push(`/article/detail/${item.id}`)">
							<b class="num">{{ `0${index+1}` }}</b>
							<h5 class="title">{{ item.title }}</h5>
							<span class="date">{{ (item.published_at).substring(0,10) }}</span>
							<p class="text">{{ item.description }}</p>
							<div class="thumb">
								<img :src="item.thumb" />
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>

		<div class="home-service">
			<div class="container">
				<div class="service-hd">
					<h4 class="cus-title">Our Service System</h4>
					<p>Our sales starts from service</p>
				</div>
				<div class="service-bd">
					<template v-for="(item, index) in services" :key="index">
						<div class="item">
							<div class="box yellow"
								:class="[
									index === 0 ? 'yellow' : '',
									index === 1 ? 'orange' : '',
									index === 2 ? 'green' : ''
								]"
							>
								<div class="point"></div>
								<div class="icon-box">
									<mall-bag v-if="index === 0" theme="filled" size="100" fill="#FFDC78" :strokeWidth="3"/>
									<setting-two v-else-if="index === 1" theme="filled" size="100" fill="#DD5536" :strokeWidth="3"/>	
									<correct v-else theme="filled" size="100" fill="#00c12b" :strokeWidth="3"/>
								</div>
								<h5>{{ item.title }}</h5>
								<p>{{ item.content }}</p>
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>

		<div class="home-case">
			<div class="container">
				<div class="case-hd">
					<h4 class="cus-title">Customer case</h4>
					<p>Exported To Over 40 Countries And Regions</p>
				</div>
				<div class="case-bd"
					:style="`
						width:${cases.list.length * 600}px;
						margin-left:-${cases.current * 600}px;
					`"
				>
					<template v-for="(item, index) in cases.list" :key="index">
						<div class="item" @click="$router.push(`/article/detail/${item.id}`)">
							<div class="thumb">
								<img :src="item.thumb" />
							</div>
							<div class="box">
								<h5 class="title">{{ item.title }}</h5>
								<p class="description">{{ item.description }}</p>
								<div class="point-box">
									<span class="point"></span>
									<b>{{ index+1 }}</b>
								</div>
								<span class="link-text">Explore</span>
							</div>
						</div>
					</template>
				</div>
				<div class="case-operation">
					<div class="left btn"
						@click="cases.current = cases.current >= 1 ? cases.current - 1 : 0"
					>
						<left theme="filled" size="34" fill="#fff" :strokeWidth="3"/>
					</div>
					<div class="line"></div>
					<div class="right btn" 
						@click="cases.current = cases.current < cases.list.length - 2 ? cases.current + 1 : 0"
					>
						<right theme="filled" size="34" fill="#fff" :strokeWidth="3"/>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { MallBag, SettingTwo, Correct, Left, Right } from '@icon-park/vue-next'
import NCarousel from '@/components/Carousel.vue'
export default {
	name: 'Home',
	components: {
		MallBag, SettingTwo, Correct, Left, Right,
		NCarousel
	},
	data(){
		return{
			carousel: {
				current: 0,
				options: []
			},
			equipments: {
				cats: [],
				data: [],
				currentId: 2
			},
			cases: {
				current: 0,
				list: []
			},
			about: {
				title: '',
				content: '',
				img: ''
			},
			services: [],
			stories: [],
		}
	},
	mounted(){
		this.getCarousels();
		this.getEquipmentCat();
		this.getEquipments();
		this.getAbout();
		this.getServices();
		this.getStories();
		this.getCases();
	},
	methods:{
		getCarousels(){
			this.$api.homeCarousels().then(res => {
				this.carousel.options = res.data;
			})
		},
		getEquipmentCat(){
			this.$api.homeEquipmentCat().then(res => {
				this.equipments.cats = res.data;
			})
		},
		getEquipments(id){
			this.$api.homeEquipments(id).then(res => {
				this.equipments.data = res.data;
			})
		},
		getAbout(){
			this.$api.homeAbout().then(res => {
				this.about = res.data;
			})
		},
		getServices(){
			this.$api.homeService().then(res => {
				this.services = res.data;
			})
		},
		getStories(){
			this.$api.homeStories().then(res => {
				this.stories = res.data;
			})
		},
		getCases(){
			this.$api.homeCases().then(res => {
				this.cases.list = res.data;
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.page-home{
	@extend .flex-column;

	.main-products{
		@extend .flex-column;
		z-index: 999;
		position: relative;
		margin: 30px 0;
		background-color: $page-color;
		.container{
			width: 1200px;
			margin: 0 auto;
		}
		.hd-box{
			text-align: center;
			margin: 30px 0;
			.cus-title{
				font-size: 42px;
				color: $orange;
			}
			.cat-links{
				@extend .flex-row-center;
				margin-top: 30px;
				.link{
					cursor: pointer;
					white-space: nowrap;
					margin: 0 15px;
					color: $text-color;
					&:hover, &.current{
						color: $orange;
					}
				}
			}
		}
		.bd-box{
			@extend .flex-row;
			a{
				position: relative;
				width: 33.333%;
				height: 400px;
				overflow: hidden;
				img{
					height: 100%;
				}
				h5{
					@extend .flex-column-center;
					z-index: 2;
					position: absolute;
					width: 100%;
					height: 100%;
					padding: 20px;
					font-family: 'cusTitle';
					font-size: 32px;
					color: $orange;
					background-color: rgba(0,0,0,.8);
					opacity: 0;
				}
				&:hover{
					h5{
						opacity: 1;
					}
					img{
						height: 110%;
						margin: -5% 0 0 -5%;
					}
				}
			}
		}
		.ft-box{
			@extend .flex-row-center;
			margin: 30px 0;
			.btn-link{
				padding: 15px 30px;
				border-radius: $radius-big;
				border: 1px solid $orange;
				color: $orange;
				&:hover{
					background-color: $orange;
					color: $white;
				}
			}
		}
	}

	.home-about{
		@extend .flex-column;
		// height: 300px;
		.container{
			width: 1200px;
			margin: 0 auto;
		}
		.about-hd{
			@extend .flex-column;
			position: relative;
			height: 330px;
			overflow: hidden;
			.bg{
				position: absolute;
				width: 100%;
			}
			.container{
				z-index: 2;
				position: relative;
				text-align: center;
				.cus-title{
					margin: 30px 0 20px;
					color: $orange;
				}
				p{
					width: 260px;
					line-height: 24px;
					margin: 0 auto;
					color: $white;
				}
			}
		}
		.about-bd{
			@extend .flex-row;
			z-index: 3;
			position: relative;
			height: 360px;
			margin-top: -140px;
			border: 2px solid $white;
			background-color: $white;
			overflow: hidden;
			.bg{
				width: 100%;
			}
			.box{
				position: absolute;
				top: 4px;
				right: 4px;
				width: 50%;
				height: 348px;
				padding: 20px;
				background-color: rgba(255,255,255,.9);
				h4{
					margin-bottom: 20px;
					font-size: 24px;
					font-family: 'cusTitle';
					color: $orange;
				}
				p{
					line-height: 32px;
				}
			}
		}
	}

	.home-story{
		margin: 50px 0;
		.container{
			width: 1200px;
			margin: 0 auto;
			.story-hd{
				@extend .flex-column;
				text-align: center;
				.cus-title{
					color: $orange;
				}
				p{
					margin-top: 15px;
					line-height: 32px;
				}
			}
			.story-bd{
				@extend .flex-row;
				margin: 30px 0;
				.item{
					@extend .flex-column;
					cursor: pointer;
					width: 33.33333%;
					padding: 5px 20px;
					border-right: 1px solid #eee;
					&:last-child{
						border-right: none;
					}
					.num{
						font-family: 'cusTitle';
						font-size: 80px;
						opacity: .05;
					}
					.title{
						font-size: 24px;
					}
					.date{
						margin: 20px 0;
						color: $info;
					}
					.text{
						height: 96px;
						line-height: 24px;
						margin-bottom: 20px;
						overflow: hidden;
					}
					.thumb{
						width: 100%;
						height: 190px;
						border-radius: $radius-big;
						background-color: #eee;
						overflow: hidden;
						img{
							width: 100%;
						}
					}
					&:hover{
						.title{
							color: $orange;
						}
						.thumb img{
							width: 110%;
							margin: -5% 0 0 -5%;
						}
					}
				}
			}
		}
	}

	.home-service{
		margin-bottom: 30px;
		.container{
			width: 1240px;
			margin: 0 auto;
		}
		.service-hd{
			@extend .flex-column-center;
			.cus-title{
				margin-bottom: 20px;
				color: $orange;
			}
		}
		.service-bd{
			@extend .flex-row;
			.item{
				width: 33.3333%;
				padding: 20px;
				.box{
					position: relative;
					height: 100%;
					padding: 50px;
					border-radius: 30px;
					background-color: $white;
					box-shadow: $shadow-default;
					.point{
						position: absolute;
						right: 20px;
						top: 20px;
						width: 0;
						height: 0;
						border-radius: 100%;
					}
					.icon-box{
						@extend .flex-column-center;
						width: 270px;
						height: 270px;
						border-radius: 100%;
						background-color: rgba(0,0,0,.05);
					}
					h5{
						margin: 60px 0 30px;
						font-size: 24px;
					}
					p{
						line-height: 30px;
						color: $grey;
					}
					&.yellow{
						.point{
							background-color: #FFDC78;
						}
						&:hover{
							h5{
								color: #FFDC78;
							}
						}
					}
					&.orange{
						.point{
							background-color: #DD5536;
						}
						&:hover h5{
							color: #DD5536;
						}
					}
					&.green{
						.point{
							background-color: $success;
						}
						&:hover h5{
							color: $success;
						}
					}
					&:hover{
						box-shadow: $shadow;
						.point{
							width: 20px;
							height: 20px;
						}
					}
				}
			}
		}
	}

	.home-case{
		position: relative;
		margin: 30px 0;
		.container{
			width: 1210px;
			margin: 0 auto;
			overflow: hidden;
		}
		.case-hd{
			@extend .flex-column-center;
			.cus-title{
				margin-bottom: 20px;
				color: $orange;
			}
		}
		.case-bd{
			@extend .flex-row;
			z-index: 2;
			position: relative;
			margin: 30px 0;
			.item{
				width: 600px;
				padding: 10px;
				cursor: pointer;
				.thumb{
					width: 580px;
					height: 300px;
					border-radius: 20px;
					overflow: hidden;
					img{
						width: 100%;
					}
				}
				.box{
					@extend .flex-column-center;
					margin-top: 20px;
					.title{
						font-size: 24px;
						font-family: 'cusTit';
					}
					.description{
						width: 50%;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						margin-top: 20px;
						color: $info;
					}
					.point-box{
						position: relative;
						.point{
							position: absolute;
							width: 10px;
							height: 10px;
							left: 50%;
							top: 50%;
							margin: -5px 0 0 -5px;
							border-radius: 100%;
							background-color: rgba(0,0,0,1);
						}
						b{
							font-size: 80px;
							font-family: 'cusTit';
							color: rgba(0,0,0,.05);
						}
					}
					.link-text{
						color: $text-color;
					}
				}
				&:hover{
					.thumb img{
						width: 110%;
						margin: -5% 0 0 -5%;
					}
					.box{
						.title{
							color: $orange;
						}
						.point{
							background-color: $orange;
						}
						.link-text{
							color: $orange;
						}
					}
				}
			}
		}
		.case-operation{
			@extend .flex-row-between;
			position: absolute;
			width: 1200px;
			margin-top: -137px;
			.btn{
				@extend .flex-column-center;
				z-index: 3;
				position: absolute;
				width: 50px;
				height: 50px;
				border-radius: 100%;
				background-color: rgba(0,0,0,.05);
				cursor: pointer;
				&:hover{
					background-color: rgba(0,0,0,.1);
				}
				&.left{
					left: 0;
				}
				&.right{
					right: 0;
				}
			}
			.line{
				width: 1100px;
				height: 1px;
				margin: 25px 0 0 50px;
				background-color: rgba(0,0,0,.05);
			}
		}
	}

}
</style>
